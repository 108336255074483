import React, { MouseEventHandler } from 'react';
import { Link } from 'gatsby';

type ILink = {
  label: string;
  to: string;
};

type IAction = {
  label: string;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const PrimaryLinkButton = ({ label, to }: ILink) => (
  <Link to={to} className="button button__primary">
    {label}
  </Link>
);

export const PrimaryActionButton = ({ label, onClick }: IAction) => (
  <button type="button" onClick={onClick} className="button button__primary">
    {label}
  </button>
);

export const LargePrimaryLinkButton = ({ label, to }: ILink) => (
  <Link to={to} className="button button__primary button__large">
    {label}
  </Link>
);

export const LargePrimaryActionButton = ({ label, onClick }: IAction) => (
  <button type="button" onClick={onClick} className="button button__large button__primary">
    {label}
  </button>
);

export const ContrastLinkButton = ({ label, to }: ILink) => (
  <Link to={to} className="button button__contrast">
    {label}
  </Link>
);

export const ContrastActionButton = ({ label, onClick, disabled }: IAction) => (
  <button type="button" onClick={onClick} className="button button__contrast" disabled={disabled}>
    {label}
  </button>
);

export const ContrastSubmitButton = ({ label }: { label: string }) => (
  <button type="submit" className="button button__contrast">
    {label}
  </button>
);

export const LargeContrastLinkButton = ({ label, to }: ILink) => (
  <Link to={to} className="button button__large button__contrast">
    {label}
  </Link>
);

export const LargeContrastActionButton = ({ label, onClick }: IAction) => (
  <button type="button" onClick={onClick} className="button button__large button__contrast">
    {label}
  </button>
);
