import { RefObject, useEffect } from 'react';

const useTrapFocus = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    if (!ref.current) return () => {};

    const listener = (event: KeyboardEvent) => {
      if (!ref.current) return null;

      if (event.key === 'Tab') {
        const focusableElements = ref.current?.querySelectorAll(
          'a[href], button, textarea, input[type="text"], select',
        );

        const firstElement = focusableElements[0] as HTMLElement;
        const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

        if (event.shiftKey && document.activeElement === firstElement) {
          lastElement.focus();
          return event.preventDefault();
        }

        if (!event.shiftKey && document.activeElement === lastElement) {
          firstElement.focus();
          event.preventDefault();
        }
      }

      return null;
    };

    document.addEventListener('keydown', listener);

    return () => document.removeEventListener('keydown', listener);
  }, [ref]);
};

export default useTrapFocus;
