import React, { Dispatch, SetStateAction, ReactNode, useEffect, useRef } from 'react';
import useOnClickOutside from '../../utils/useOnClickOutside';
import useTrapFocus from '../../utils/useTrapFocus';

const CloseModalSvg = ({ setShowModal }: { setShowModal: Dispatch<SetStateAction<boolean>> }) => (
  <button
    className="absolute top-2 right-2"
    type="button"
    aria-label="close modal button"
    onClick={() => setShowModal(false)}
  >
    <svg
      className="h-6 w-6 text-primary"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>
);

type Props = {
  children: ReactNode;
  ariaLabel: string;
  className: string;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const Modal = ({ children, ariaLabel, className, showModal, setShowModal }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(modalRef, () => setShowModal(false));
  useTrapFocus(modalRef);

  useEffect(() => {
    if (!modalRef) return () => {};

    const keyListener = (event: KeyboardEvent) => {
      if (!modalRef.current) return () => {};

      if (event.key === 'Escape') {
        setShowModal(false);
        return event.preventDefault();
      }

      return null;
    };

    if (showModal) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', keyListener);
    }

    const cleanup = () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('keydown', keyListener);
      return null;
    };

    return () => cleanup();
  }, [showModal]);

  return (
    <div
      style={{ maxWidth: '100%' }}
      className="fixed top-0 left-0 flex-center bg-primary-lightest bg-opacity-70 w-full min-h-full z-20"
      aria-modal
      role="dialog"
      aria-label={ariaLabel}
    >
      <div
        ref={modalRef}
        style={{ maxWidth: '95%' }}
        className={`inner-modal relative bg-primary-lighter rounded border-4 border-contrast py-10 md:py-4 ${className}`}
      >
        <CloseModalSvg setShowModal={setShowModal} />

        {children}
      </div>
    </div>
  );
};

export default Modal;
